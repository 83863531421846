// this store is for retaining user selections
//fantastic


export default {
    namespaced: true,
    state: {
        quizOneSelections: [],
        quizTwoSelections: [],     
    },
    getters: {
        // this getter returns an object of question key and selections as value
        getQuizOneSelections(state) {
            let selections = {};
            
            if(state.quizOneSelections.length > 0) {
                for (const value of Object.values(state.quizOneSelections[0])) {
                    selections[value.Question] = value.Selection
                }
            }
            return selections
        }
    },

    mutations: {
        //this saves the user's selection of quiz One.
        saveQuizOneSelection(state, payload) {
            state.quizOneSelections = [payload]
        },

        //this saves the user's selection of quiz Two.
        saveQuizTwoSelection(state, payload) {
            state.quizTwoSelections = [payload]
        },

        //clears user's selections when quiz is submitted.
        clearUserSelections(state) {
            state.quizOneSelections = [];
            state.quizTwoSelections = [];
        }
    },
    actions: {}
}