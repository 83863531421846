import { getAPI } from '../../../utils/api'


export default ({
    state: {
        accessToken: null,
        refreshToken: null,
        APIData: '',
        error: false,
        quizTaker: {},
        users: [],
    },
    mutations: {
        updateStorage(state, { access, refresh }) {
            state.accessToken = access
            state.refreshToken = refresh
        },
        destroyToken(state) {
            state.accessToken = null
            state.refreshToken = null
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token');
        },
        setQuizTaker(state, user_data) {
            state.quizTaker = user_data
        },

        setUsers(state, users) {
            state.users = users
        }
    },
    actions: {
        clearTokens(context) {
            context.commit('destroyToken')
        },

        userLogout(context) {
            if (context.getters.loggedIn) {
                context.commit('destroyToken')
            }
        },

        createQuizTaker(context, quiztaker) {
            return new Promise((resolve, reject) => {
                getAPI.post('/api/user/create/', {
                    name: quiztaker.name,
                    email: quiztaker.email,
                    phone: quiztaker.phone,
                    position: quiztaker.position,
                    organization: quiztaker.organization,
                    date_of_birth: quiztaker.dob,
                    city: quiztaker.city,
                    country: quiztaker.country,
                })
                    .then(response => {
                        context.commit('setQuizTaker', response.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err.response.status)
                    })
            })
        },
        fetchUsers(context) {
            return new Promise((resolve, reject) => {
                getAPI.get('/api/user/list')
                    .then(response => {
                        context.commit('setUsers', response.data)
                        resolve()
                    })
                    .catch(err => {
                        reject(err.response.status)
                    })
            })
        },

        sendInvitation(context, email) {
            return new Promise((resolve, reject) => {
                getAPI.post('/api/user/invite/', {
                    email: email
                })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(err => {
                        reject(err.response.status)
                    })
            })
        },
        sendComment(context, data) {
            return new Promise((resolve, reject) => {
                getAPI.post('/api/user/comment/', {
                    quiztaker_id: data.quiztaker_id,
                    comment: data.comment,
                })
                    .then(response => {
                        resolve() 
                        console.log(response)
                    })
                    .catch(err => {
                        reject(err.response.status)
                    })
            })
        }
    },
    getters: {
        userToken(state) {
            return state.accessToken
        }
    },
})