import user from "./modules/user";
import quiz from "./modules/quiz";
import selection from "./modules/selection"
import auth from "./modules/auth";
import VuexPersist from 'vuex-persist';
import Vuex from "vuex";
// vuex local storage
const vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.localStorage,
    reducer: state => ({
        user: state.user,
        quiz: state.quiz,
        auth: state.auth
    })
})

export default new Vuex.Store({
    plugins: [vuexLocalStorage.plugin],
    modules: {
        user,
        quiz,
        selection,
        auth
    },

    actions: {},
});
