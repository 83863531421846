import axios from 'axios'
import LocalStorageService from "./token";
import router from "../router";


const baseURL = process.env.VUE_APP_ROOT_API;

const getAPI = axios.create({
    baseURL: baseURL,
    // 2 mins timeout
    timeout: 120000,
})

export { getAPI }


// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
getAPI.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken()
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config
    },
    error => {
        Promise.reject(error)
    });



//Add a response interceptor
getAPI.interceptors.response.use(
    (response) => {
        // nprogress.done()
        return response;
    },
    async function (error) {
        const originalRequest = error.config;

        if (
            error.response.status === 401 &&
            originalRequest.url === `/api/login/`
            ) {
                router.push({ name: "AdminLogin" });
                return Promise.reject(error);
            }
            if (
                error.response.status === 401 &&
                originalRequest.url === `/api/token/refresh/`
                ) {
            router.push({ name: "AdminLogin" });
            return Promise.reject(error);
        }

        if (error.response.status === 403) {
            originalRequest._retry = true;
            const refreshToken = localStorageService.getRefreshToken();
            const res = await getAPI.post("/api/token/refresh/", {
                refresh: refreshToken,
            });
            if (res.status === 200) {
                localStorageService.setToken(res.data);
                getAPI.defaults.headers.common["Authorization"] =
                    "Bearer " + localStorageService.getAccessToken();
                return getAPI(originalRequest);
            } else {
                localStorageService.clearToken()
            }
        }

        if (error.response.status === 404) {
            Promise.reject(error);
        }
        return Promise.reject(error);
    }
);