import { getAPI } from "../../../utils/api"
export default ({
    state: {
        quiztaker_id: null,
        quiz_id: null,
        users_answers: [], 
        quiz_two_answers: [],
        results: [],
        comment: null,
    },
    mutations: {
        SET_QUIZTAKER_ID(state, quiztaker_id) {
            state.quiztaker_id = quiztaker_id
        },

        SET_QUIZ_ANSWERS(state, users_answers) {
            state.users_answers = users_answers
        },

        SET_QUIZ_RESULTS(state, results) {
            state.results = results
        },

        SET_QUIZ_TWO_ANSWERS(state, quiz_two_answers) {
            state.quiz_two_answers = quiz_two_answers
        },

        SET_QUIZ_COMMENT(state, comment) {
            state.comment = comment
        }
    },
    actions: {
        setAnswers(context, userresponse) {
            context.commit('SET_QUIZTAKER_ID', userresponse.quizTaker)
        },

        submitAnswers(context, quiz_data) {
            return new Promise(() => {
                getAPI
                .post(`api/quiz/save/`, {
                    quiz_type: quiz_data.quiz_type,
                    quiztaker_id: quiz_data.quiztaker_id,
                    quiz: quiz_data.quiz_id,
                    users_answer: quiz_data.users_answers,
                }, )
                .then((response) => {
                    // if(status === 200) {
                    const results = []
                    let section_object = {
                        section_a: {
                            title: 'Section A',
                            data: response.data.quiz_taker.section_a
                        },
                        section_b: {
                            title: 'Section B',
                            data: response.data.quiz_taker.section_b
                        },
                        section_c: {
                            title: 'Section C',
                            data: response.data.quiz_taker.section_c
                        },
                    }
                    results.push(section_object)
                    context.commit('SET_QUIZ_RESULTS', results)
                    context.commit('SET_QUIZTAKER_ID', response.data.quiz_taker.id)
                    // }
                    console.log(response.status)
                })
            })
        },

        getResults(context, quiztaker_id) {
            return new Promise((resolve, reject) => {
                getAPI
                .post(`api/quiz/quiztaker/`, {quiztaker_id: quiztaker_id})
                .then((response) => {
                    const results = []
                    let section_object = {
                        section_a: {
                            title: 'Section A',
                            data: response.data.section_a
                        },
                        section_b: {
                            title: 'Section B',
                            data: response.data.section_b
                        },
                        section_c: {
                            title: 'Section C',
                            data: response.data.section_c
                        },
                    }
                    results.push(section_object)
                    context.commit('SET_QUIZ_RESULTS', results)
                    context.commit('SET_QUIZ_COMMENT', response.data.comment)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            })
        },

        sendInvite(context, email) {
            return new Promise(() => {
                getAPI
                .post(`api/user/send-invitation/`, {
                    email: email,
                }, )
                .then((response) => {
                    alert(response.data.message)
                    console.log(response.status)
                })
                .catch((error) => {
                    alert(error.response.data.message)
                })
            })
        },

        completeQuiz(context, quiz_data) {
            return new Promise((resolve, reject) => {
                getAPI
                .post(`api/quiz/submit/`, {
                    quiztaker_id: quiz_data.quiztaker_id,
                }, )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
            })
        }
    },

    modules: {
    }
})
