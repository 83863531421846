import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// config tailwind
import '@/utils/tailwind.css'
import Vuex from "vuex";

import VueTippy from 'vue-tippy'

//fonts
import "@fontsource/merriweather-sans"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faUserCircle, faBell, faSearch, faClose, faBook, faUsers, faMailForward } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faInfoCircle, faUserCircle, faBell, faSearch, faClose, faBook, faUsers, faMailForward)

// route to login if not logged in
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = store.getters.isAuthenticated;
    if (requiresAuth && !isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

createApp(App).use(VueTippy).use(store).use(Vuex).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')