import { getAPI } from '../../../utils/api'

export default ({
    state: {
        accessToken: null,
        refreshToken: null,
        APIData: '',
        notifications: [],
    },

    mutations: {
        updateStorage(state, { access, refresh }) {
            state.accessToken = access
            state.refreshToken = refresh
        },
        destroyToken(state) {
            state.accessToken = null
            state.refreshToken = null
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token');
        },
        setNotifications(state, notifications) {
            state.notifications = notifications
        }
    },

    actions: {
        userLogout(context) {
            if (context.getters.loggedIn) {
                context.commit('destroyToken')
            }
        },

        // login
        adminLogin(context, userCredentials) {
            return new Promise((resolve, reject) => {
                getAPI.post('/api/login/', {
                    username: userCredentials.username,
                    password: userCredentials.password
                })
                .then(response => {
                    const access = response.data.access
                    const refresh = response.data.refresh
                    localStorage.setItem('access_token', access)
                    localStorage.setItem('refresh_token', refresh)
                    context.commit('updateStorage', { access, refresh })
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
            })
        },

        fetchNotifications(context) {
            return new Promise((resolve, reject) => {
                getAPI.get('/api/notifications/list/')
                    .then(response => {
                        context.commit('setNotifications', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        markNotificationAsRead(context, notificationId) {
            return new Promise((resolve, reject) => {
                getAPI.post(`/api/notifications/${notificationId}/read/`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        markAllNotificationsAsRead() {
            return new Promise((resolve, reject) => {
                getAPI.post(`/api/notifications/read-all/`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },

    getters: {
        isAuthenticated(state) {
            return state.accessToken != null
        },
        loggedIn(state) {
            return state.accessToken != null
        },
    },
})