<template>
  <div class="bg-slate">
    <div
      class="
        home
        max-w-screen-xl
        m-auto
        min-h-screen
        lg:flex
        relative
        px-3
        lg:px-5
        overflow-x-hidden overflow-y-hidden
      "
    >
      <!-- HOME CONTENT -->
      <div class="w-full md:w-3/5 h-screen lg:w-1/2 container">
        <div class="flex flex-col items-center justify-center h-screen">
          <!-- <div class="logo w-28 md:w-44 pt-5">
            <img
              src="@/assets/Images/home/logo.png"
              alt="precept"
              class="w-full"
            />
          </div> -->
          <div class="content">
            <div class="title mb-8">
              <h1 class="lg:text-xxl text-xl font-headers">
                Welcome <br /><span class="font-bold text-secondary"
                  >To The BRET</span
                >
              </h1>
            </div>
            <div class="content mb-8">
              <p class="lg:text-base">
                This platform identifies your key and pre-dominant drivers as
                enunciated by the 4 Drive Theory of Motivation. This is
                indicative of your Natural or Instinctive behavior & response.
              </p>
            </div>
            <div class="button">
              <router-link
                :to="{ name: 'Register' }"
                href="#"
                class="btn max-w-xs"
                >Get Started</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <!-- HOME IMAGE -->
      <div class="lg:w-1/2 hidden md:flex justify-center items-center">
        <div class="w-full bottom-0 z-10">
          <img class="w-full" src="@/assets/Images/home/bret.png" alt="bret" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Landing",
};
</script>

<style scoped>
</style>