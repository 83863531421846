import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/LandingPage.vue'

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/quiz/1',
    name: 'QuizOne',
    component: () => import('../views/QuizPageOne.vue')
  },
  {
    path: '/quiz/2',
    name: 'QuizTwo',
    component: () => import('../views/QuizPageTwo.vue')
  },
  {
    path: '/quiz/3',
    name: 'QuizThree',
    component: () => import('../views/QuizPageThree.vue')
  },
  {
    path: '/quiz/hurray',
    name: 'SuccessPage',
    component: () => import('../views/SuccessPage.vue')
  },
  {
    path: '/quiz/results',
    name: 'Results',
    component: () => import('../views/TheResults.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/AdminPage.vue'),
    // isLoggedIn
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'AdminLogin',
    component: () => import('../views/admin/AdminLogin.vue')
  },

  // 404 page
  // {
  //   path: '/:catchAll(.*)',
  //   name: 'NotFound',
  //   component: () => import('../views/NotFound.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    
    if (to.hash) {
      return {
        el: to.hash,
        top: 120,
        behavior: 'smooth'
      }
    }

    // return  {top : 0}

    return new Promise((resolve) => {
      setTimeout(() => resolve({ top: 0}), 300)
    })
    
  },
})

export default router
