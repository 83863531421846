<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" :key="$route.path"></component>
      </transition>
    </router-view>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;300;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&family=Noto+Sans:wght@100;300;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.btn {
  @apply bg-tetiary px-8 py-4 bg-opacity-100 rounded-md  hover:bg-opacity-90 text-white font-semibold block text-center  cursor-pointer transition;
}

.background {
  background: linear-gradient(89.81deg, #0f223c 0.13%, #1e4276 103.71%);
}

.bx-sdw {
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
